import React, { useState, useEffect, useRef, useContext } from 'react';
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import moment from "moment";
import "./Checkout.scss";
import { useNavigate } from "react-router-dom";
import { CartContext } from '../../pages/Home/CartContext';
import { baseUrlPostGres } from "../../redux/constant";
import { useDispatch } from 'react-redux';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
// paypal email send
import emailjs from 'emailjs-com';
//import { PDFDocument, StandardFonts, rgb } from 'pdf-lib';
import { PDFDocument, rgb } from 'pdf-lib';
import axios from 'axios';
import { jsPDF } from 'jspdf';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
// for email send
// Checkout.js
import BankTransferForm from "./BankTransferForm";
import { loadStripe } from '@stripe/stripe-js';
import { CardElement, useStripe, useElements, Elements } from '@stripe/react-stripe-js';
import { apiHeader } from "../../redux/actions/ApiHeader";
import { getUserSettings } from "../../Utils/Function";

const Checkout = () => {
  const [paymentMethod, setPaymentMethod] = useState('');
  const [selectedOption, setSelectedOption] = useState('qrCode');
  const handleOptionChange = (option) => {
    setSelectedOption(option);
  };

  // cart iteam
  const [itemPrices, setItemPrices] = useState({});
  const { cartItems, setCartItems } = useContext(CartContext);
  useEffect(() => {
    const storedCartItems = localStorage.getItem('cartItems');
    if (storedCartItems) {
      setCartItems(JSON.parse(storedCartItems));
    }
  }, []);

  // subtotal
  const [subtotal, setSubtotal] = useState(0);
  // Calculate subtotal when cartItems change
  useEffect(() => {
    const total = cartItems.reduce((acc, item) => {
      if (item.price && !isNaN(item.price)) {
        return acc + parseFloat(item.price);
      }
      return acc;
    }, 0);
    setSubtotal(total);
  }, [cartItems]);

  //iteamprice
  useEffect(() => {
    let total = 0;
    const prices = {};
    cartItems.forEach(item => {
      if (item.price && !isNaN(item.price)) {
        total += parseFloat(item.price);
        prices[item.id] = parseFloat(item.price);
      }
    });
    setSubtotal(total);
    setItemPrices(prices);
  }, [cartItems]);
  //paypal
  const paypalOptions = {
    'client-id': 'ATBqKPZsH5D2rnAG0-71CrvmoNalm_iUbYvtIwoQ987Ny6qffbeoIjc-IJ9yxa2pAgICJUZtzjlbVkp8',
    currency: 'USD'
  };

  const styles = {
    layout: 'vertical',
    color: 'blue',
    shape: 'rect',
    label: 'paypal',
  };
  //email sent
  emailjs.init('32CQzXtiO8B3mjE--'); // Initialize EmailJS with your User ID
  const generatePdfAndSendEmail = async (details) => {
    //new
    const { purchase_units, payer } = details;
    const username = details.payer.name.given_name;
    const payerAddress = payer.address || {};
    //const amount = purchase_units[0].amount.value;
    const itemId = 0; // Replace 0 with the actual item ID or index
    const amount = purchase_units[0].amount.value;
    const transactionId = details.id;
    const email = details.payer.email_address
    // Construct shipping address safely
    const shippingAddressParts = [
      payerAddress.address_line_1,
      payerAddress.admin_area_2,
      payerAddress.admin_area_1,
      payerAddress.country_code,
      payerAddress.postal_code
    ].filter(part => part); // Filter out undefined or null parts
    const shippingAddress = shippingAddressParts.join(', '); // Join address parts
    const invoiceDate = moment().format("MMM DD, YYYY");
    // Prepare item details for sending to backend
    const itemNames = cartItems.map(item => item.class_name).join(', ');
    const itemAmounts = cartItems.map(item => item.price).join(', ');

    try {
      const response = await axios.post('http://127.0.0.1:8000/api/send-email', {
        username,
        email,
        amount: itemAmounts,
        itemName: itemNames,
        transactionId,
        shippingAddress,
        invoiceDate,
        subTotal: amount,
        // username,
      });

      console.log(response);
      console.log('Email sent:', response.data); // Log success response
      alert(response.data.message); // Display success message to user
    } catch (error) {
      console.error('Failed to send email:', error); // Log error for debugging
      alert('Failed to send invoice email'); // Notify user about failure
    }
  }

  /*stripe*/
  const handlePaymentMethodChange = (event) => {
    setPaymentMethod(event.target.value);
  };
  const cardElementOptions = {
    style: {
      base: {
        color: '#32325d',
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: 'antialiased',
        fontSize: '16px',
        '::placeholder': {
          color: '#aab7c4',
        },
      },
      invalid: {
        color: '#fa755a',
        iconColor: '#fa755a',
      },
    },
  };

  const localStorageData = getUserSettings();
  const stripe = useStripe();
  const elements = useElements();
  const [email, setEmail] = useState(localStorageData.user.email);
  const [name, setName] = useState(localStorageData.user.first_name + " " + localStorageData.user.last_name);
  const [userId, setUserId] = useState(localStorageData.user.id);
  const [interval, setInterval] = useState();
  const [error, setError] = useState(null);
  const [succeeded, setSucceeded] = useState(false);
  const [submitClick, setSubmitClick] = useState(false);
  //const classArray = cartItems.map(item => item.class_name);
  const productDetails = cartItems.map(item => ({
    name: item.class_name,
    price: item.price,
  }));

  const handleSubmit = async (event) => {
    event.preventDefault();
    setSubmitClick(true);
    setError(null);

    if (!stripe || !elements) {
      setError('Stripe.js has not loaded yet.');
      setSubmitClick(false);
      return;
    }

    const cardElement = elements.getElement(CardElement);
    const { error, paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: cardElement,
    });

    if (error) {
      setError(error);
      setSucceeded(false);
      return;
    }

    try {
        const response = await axios.post(`${baseUrlPostGres()}/api/create-subscription`, {
            email_id: email,
            user_name: name,
            user_id:userId,
            interval: interval,
            payment_method_id: paymentMethod.id,
            products: productDetails,
        });

        const subscription = response.data;

        // Confirm the payment if required
        if (subscription.latest_invoice.payment_intent.status === 'requires_action') {
            const { error: confirmError } = await stripe.confirmCardPayment(
              subscription.latest_invoice.payment_intent.client_secret
            );

            if (confirmError) {
              //setError(`Error confirming card payment: ${confirmError.message}`);
              //setSucceeded(false);
              //console.error('Error confirming card payment:', confirmError);
            } else {
              setSucceeded(true);
              console.log('Subscription created and payment confirmed:', subscription);
            }
        } else {
          setSucceeded(true);
          console.log('Subscription created:', subscription);
        }
        setSucceeded(true);
        console.log('Subscription created:', response.data);
    } catch (error) {
      setSucceeded(false);
      //setError(`Error creating subscription: ${error.response ? error.response.data : error.message}`);
      //setError('Error creating subscription:', error.response ? error.response.data : error.message);
      
      // Improved error message handling
      const errorMessage = error.response?.data?.message || error.response?.data?.error || error.message || 'Unknown error occurred';

      setError(`Error creating subscription: ${errorMessage}`);
    }
    setSubmitClick(false);
  };

  // Step 1: Stripe ACH Direct Debit (Bank To Stripe Payment)
  const [accountNumber, setAccountNumber] = useState('');
  const [routingNumber, setRoutingNumber] = useState('');
  const [accountHolderName, setAccountHolderName] = useState('');
  const [emailId, setEmailId] = useState('');
  const [validateMsg, setValidateMsg] = useState('');
  const [paymentStatus, setPaymentStatus] = useState('Pay Now'); // 'Pay Now', 'Error', 'Success'
  const [achSubmitClick, setAchSubmitClick] = useState(false);
  const [achInterval, setAchInterval] = useState('');

  const validateForm = () => {
    if (!accountNumber || !routingNumber || !accountHolderName || !emailId) {
      setValidateMsg('All fields are required.');
      return false;
    }

    if (accountNumber.length < 10) {
      setValidateMsg('Account number must be at least 10 digits long.');
      return false;
    }
    if (routingNumber.length !== 9) {
      setValidateMsg('Routing number must be exactly 9 digits.');
      return false;
    }
    // You can add more validation as needed
    return true;
  };

  const createBankPayment = async () => {
    setAchSubmitClick(true);
    setPaymentStatus('Pay Now'); // Reset to Pay Now during process

    if (!validateForm()) {
      setAchSubmitClick(false);
      return;
    }

    try {
      //const response = await axios.post(`${baseUrlPostGres()}/api/create-bank-payment`, {
      const response = await axios.post(`${baseUrlPostGres()}/api/send-bank-paymen-trequest-to-admin`, {
        account_number: accountNumber,
        routing_number: routingNumber,
        account_holder_name: accountHolderName,
        email_id: email,
        user_id: userId,
        interval: achInterval,
        products: productDetails,
      });

      if (response.data.success) {
        setPaymentStatus('Success');
      } else {
        setPaymentStatus('Error');
      }
    } catch (error) {
      setPaymentStatus('Error');
    }
    finally {
      setAchSubmitClick(false);
    }
  };

  return (
    <>
      <Header />
      <div id="footer-bottom">
        <div className="containers">
          <div className="left-column">
            <h1 className="heading">Checkout</h1>
            {/* Billing address */}
            <div className="section"> <h2 className="section-heading">Billing address</h2> <div className="flex-container"> <div className="input-wrapper"> <label htmlFor="country">Country</label> <select id="country" name="country" className="dropdown"> <option value="India">India</option> <option value="USA">USA</option> <option value="UK">UK</option> </select> </div> <div className="input-wrapper"> <label htmlFor="state">State / Union Territory</label> <select id="state" name="state" className="dropdown"> <option value>Please select...</option> <option value="Delhi">Delhi</option> <option value="Maharashtra">Maharashtra</option> </select> </div> </div> <p className="note">Udemy is required by law to collect applicable transaction taxes for purchases made in certain tax jurisdictions.</p> </div>
            {/* Payment method */}
            {/* Payment method with toggle functionality */}
            {/* Payment method with toggle functionality */}
            <div className="section">
              <h2 className="section-heading">Payment method</h2>
              <div className="secure-note">
                <span className="lock-icon">🔒</span> Secured connection
              </div>
              <>
                <div className="payment-method">
                  {/* Credit/Debit Card */}
                  <input
                    type="radio"
                    id="credit-debit-card"
                    name="payment"
                    value="credit-debit-card"
                    className="payment-radio"
                    onChange={handlePaymentMethodChange}
                  />
                  <label htmlFor="credit-debit-card" className="payment-label">
                    <div className="payment-detail">
                      <span className="radio-circle" /> Credit/Debit Card
                    </div>
                    <div className="payment-icons">
                      {/* <img src="Visa.png" alt="Visa" /> */}
                      <img src="../images/card/card-visa.svg" alt="Visa" />
                      <img src="../images/card/card-mastercard.svg" alt="MasterCard"></img>
                      <img src="../images/card/card-amex.svg" alt="Amex" />
                      <img src="../images/card/card-dinersclub.svg" alt="Dinersclub" />
                      <img src="../images/card/card-rupay.svg" alt="RuPay" />
                    </div>
                  </label>
                  <div className="credit-card-details">
                    <label htmlFor="name-on-card">Name on card</label>
                    <input
                      type="text"
                      id="name-on-card"
                      className="input-field"
                      placeholder="Name on card"
                    />
                    <label htmlFor="card-number">Card number</label>
                    <input
                      type="text"
                      id="card-number"
                      className="input-field"
                      placeholder="1234 5678 9012 3456"
                    />
                    <div className="card-info-icons">
                      <img src="../images/card/card-visa.svg" alt="Visa" className="small-icon" />
                      <img src="../images/card/card-mastercard.svg"  alt="Mastercard" className="small-icon" />
                      <img src="../images/card/card-amex.svg" alt="Amex" className="small-icon" />
                      <img src="../images/card/card-dinersclub.svg" alt="Dinersclub" className="small-icon" />
                      <img src="../images/card/card-rupay.svg" alt="RuPay" className="small-icon" />
                    </div>
                    <div className="date-and-cvc">
                      <div className="input-group">
                        <label htmlFor="expiry-date">Expiry date</label>
                        <input
                          type="text"
                          id="expiry-date"
                          className="input-field"
                          placeholder="MM/YY"
                        />
                      </div>
                      <div className="input-group">
                        <label htmlFor="cvc">CVC / CVV</label>
                        <input
                          type="text"
                          id="cvc"
                          className="input-field"
                          placeholder="CVC"
                        />
                      </div>
                    </div>
                    <label className="checkbox-label">
                      <input type="checkbox" name="save-card" className="checkbox" />
                      Securely save this card for my later purchase
                    </label>
                  </div>
                  {/*Stripe Bank To Bank Payment */}
                  <input type="radio" id="stripe-ach" name="payment" className="payment-payment-radio" value="stripe-ach" onChange={handlePaymentMethodChange}/>
                  <label htmlFor="stripe-ach" className="payment-payment-label">
                    <div className="payment-payment-detail">
                      <span className="radio-circle" /> Bank To Bank Payment
                    </div>
                  </label>
                  {paymentMethod === 'stripe-ach' && (
                    <>
                      <div className="ach-bank-form-container">
                        {validateMsg && <p className="error-message">{validateMsg}</p>}
                        <label>Account Holder Name *</label>
                        <input
                          type="text"
                          placeholder="Account holder name"
                          value={accountHolderName}
                          onChange={(e) => setAccountHolderName(e.target.value)}
                          className="input-field"
                        />
                        <label>Account Number *</label>
                        <input
                          type="text"
                          placeholder="Account number"
                          value={accountNumber}
                          onChange={(e) => setAccountNumber(e.target.value)}
                          className="input-field"
                        />
                        <label>Routing Number *</label>
                        <input
                          type="text"
                          placeholder="Routing number"
                          value={routingNumber}
                          onChange={(e) => setRoutingNumber(e.target.value)}
                          className="input-field"
                        />
                        <label>Email *</label>
                        <input
                          type="email"
                          placeholder="Email"
                          value={emailId}
                          onChange={(e) => setEmailId(e.target.value)}
                          className="input-field"
                        />
                        <label>Interval *</label>
                        <select className="input-field" value={achInterval} onChange={(e) => setAchInterval(e.target.value)} required>
                          <option value="">Select Interval</option>
                          <option value="day">Daily</option>
                          <option value="week">Weekly</option>
                          <option value="month">Monthly</option>
                          <option value="year">Yearly</option>
                        </select>
                        {paymentStatus === 'Error' && (
                          <div className="error" style={{
                            backgroundColor: '#dc3545',
                            color: 'white',
                            border: 'none',
                            padding: '10px',
                            fontSize: '16px',
                            borderRadius: '4px',
                            width: '100%',
                            textAlign: 'center',
                            display: 'block',
                            marginTop: '20px'
                          }}>Something went wrong. Please try again.</div>
                        )}

                        {paymentStatus !== 'Success' ? (
                          <button
                            disabled={achSubmitClick}
                            onClick={createBankPayment}
                            className="submit-button"
                            style={{
                              backgroundColor: achSubmitClick ? '#cccccc' : '#0070ba',
                              color: 'white',
                              border: 'none',
                              padding: '10px 32px',
                              fontSize: '16px',
                              borderRadius: '4px',
                              cursor: achSubmitClick ? 'not-allowed' : 'pointer',
                              width: '100%',
                              textAlign: 'center',
                              display: 'block',
                              marginTop: '20px',
                              transition: 'background-color 0.3s ease'
                            }}
                          >
                            {achSubmitClick ? 'Processing...' : paymentStatus}
                          </button>
                        ) : (
                          <div className="success" 
                            style={{
                              backgroundColor: '#14A44D',
                              color: 'white',
                              border: 'none',
                              padding: '10px',
                              fontSize: '16px',
                              borderRadius: '4px',
                              width: '100%',
                              textAlign: 'center',
                              display: 'block',
                              marginTop: '20px'
                            }}
                          >
                            Payment request sent to admin successfully processed!
                          </div>
                        )}
                      </div>
                    </>
                  )}

                  {/*stripe*/}
                  <input type="radio" id="stripe" name="payment" className="payment-payment-radio" value="stripe" onChange={handlePaymentMethodChange}/>
                  <label htmlFor="stripe" className="payment-payment-label">
                    <div className="payment-payment-detail">
                      <span className="radio-circle" /> Stripe Payment
                    </div>
                  </label>
                  {paymentMethod === 'stripe' && (
                    <>
                    <div className="stripe-card-details">
                      <form onSubmit={handleSubmit}>
                        <CardElement options={cardElementOptions} />
                        <select value={interval} onChange={(e) => setInterval(e.target.value)} required style={{
                          width: '100%',
                          padding: '8px 10px',
                          fontSize: '16px',
                          borderRadius: '2px',
                          border: '1px solid #ddd',
                          transition: 'border-color 0.3s ease',
                          marginBottom: '15px',
                          marginTop: '15px',
                        }}>
                          <option value="">Select Interval</option>
                          <option value="day">Daily</option>
                          <option value="week">Weekly</option>
                          <option value="month">Monthly</option>
                          <option value="year">Yearly</option>
                        </select>
                        {error && <div className="error" style={{
                          backgroundColor: '#dc3545',
                          color: 'white',
                          border: 'none',
                          padding: '10px',
                          fontSize: '16px',
                          borderRadius: '4px',
                          width: '100%',
                          textAlign: 'center',
                          display: 'block',
                          marginTop: '20px'
                        }}>{error}</div>}
                        {!succeeded ? (
                          <button
                            type="submit"
                            disabled={submitClick}
                            style={{
                              backgroundColor: submitClick ? '#cccccc' : '#0070ba',
                              color: 'white',
                              border: 'none',
                              padding: '10px 32px',
                              fontSize: '16px',
                              borderRadius: '4px',
                              cursor: submitClick ? 'not-allowed' : 'pointer',
                              width: '100%',
                              textAlign: 'center',
                              display: 'block',
                              marginTop: '20px',
                              transition: 'background-color 0.3s ease'
                            }}
                            >{submitClick ? 'Processing...' : 'Stripe Checkout'}
                          </button>
                        ) : (
                          <div className="success" 
                            style={{
                              backgroundColor: '#14A44D',
                              color: 'white',
                              border: 'none',
                              padding: '10px',
                              fontSize: '16px',
                              borderRadius: '4px',
                              width: '100%',
                              textAlign: 'center',
                              display: 'block',
                              marginTop: '20px'
                            }}>Payment successfully processed!
                          </div>
                        )}
                      </form>
                    </div>
                  </>
                  )}

                  {/* UPI */}
                  <input type="radio" id="upi" name="payment" value="upi" className="payment-radio" onChange={handlePaymentMethodChange} />
                  <label htmlFor="upi" className="payment-label">
                    <div className="payment-detail">
                      <span className="radio-circle" /> Pay Via Payal
                    </div>

                  </label>
                  <div className="upi-details">
                    <PayPalScriptProvider options={paypalOptions}>
                      <PayPalButtons
                        style={styles}
                        createOrder={(data, actions) => {
                          return actions.order.create({
                            purchase_units: [
                              {
                                amount: {
                                  value: `${Number(subtotal).toFixed(2)}`
                                }
                              }
                            ]
                          });
                        }}

                        onApprove={(data, actions) => {
                          return actions.order.capture().then((details) => generatePdfAndSendEmail(details));
                        }}
                      />
                    </PayPalScriptProvider>
                  </div>

                  {/* netbanking */}
                  <input
                    type="radio"
                    id="netbanking"
                    name="payment"
                    value="netbanking"
                    className="payment-radio"
                    onChange={handlePaymentMethodChange}
                  />
                  <label htmlFor="netbanking" className="payment-label">
                    <div className="payment-detail">
                      <span className="radio-circle" /> NetBanking
                    </div>
                  </label>
                  <div className="netbanking-details">
                    <p>
                      In order to complete your transaction, we will transfer you over to
                      Adyen's secure servers.
                    </p>
                    <label htmlFor="bank">Select your bank:</label>
                    <select id="bank" className="bank-dropdown">
                      <option value="">Select a bank</option>
                      <option value="airtel">Airtel Payment Bank</option>
                      <option value="andhra">Andhra Pragathi Grameena Bank</option>
                      <option value="au">AU Small Finance Bank</option>
                      <option value="axis">Axis Bank</option>
                      <option value="bandhan">Bandhan Bank</option>
                      <option value="bahrain">Bank of Bahrain and Kuwait</option>
                      <option value="bob">Bank of Baroda - Retail Banking</option>
                      <option value="boi">Bank of India</option>
                      <option value="bom">Bank of Maharashtra</option>
                    </select>
                  </div>
                  {/* mobile wallete */}
                  <input
                    type="radio"
                    id="mblwlt"
                    name="payment"
                    value="mobile-wallet"
                    className="payment-radio"
                    onChange={handlePaymentMethodChange}
                  />
                  <label htmlFor="mblwlt" className="payment-label">
                    <div className="payment-detail">
                      <span className="radio-circle" /> Mobile wallet
                    </div>

                  </label>

                  <div className="mobilewallete-details">
                    <p>
                      In order to complete your transaction, we will transfer you over to
                      Adyen's secure servers.
                    </p>
                    <label htmlFor="bank">Select your bank:</label>
                    <select id="bank" className="bank-dropdown">
                      <option value="">Select a bank</option>
                      <option value="airtel">Amazon Pay</option>
                      <option value="andhra">AAirtel Money</option>
                      <option value="au">EbixCash</option>
                      <option value="axis">Freecharge Wallet</option>
                      <option value="bandhan">PhonePe Wallet</option>
                    </select>
                  </div>
                </div>
              </>
            </div>
            {/* netbanking */}

            {/* Order details */}
            <div className="section">
              <h2 className="section-heading">Order details</h2>
              <ul className="order-details">
                {cartItems.map((item, index) => (
                  <li key={index}>
                    <div className="product-item">
                      <div className="product-info">
                        <div className="left-side">
                          <img src={baseUrlPostGres() + item.picture} alt="Product" />
                          <span className="product-title">{item.class_name}</span>
                          {/* <span>Created {moment(item.created_at, "YYYY/MM/DD").format("MMM DD YYYY")}</span> */}
                          <span className="price">${item.price}</span>
                        </div>
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          {/* Summary */}
          <div className="right-column">
            <h2 className="summary-heading">Summary</h2>
            <div className="summary">
              <div className="summary-item">
                <span>Original Price:</span>
                <span className="summary-item">${Number(subtotal).toFixed(2)}</span>
              </div>
              <div className="summary-item">
                <span>Discounts:</span>
                <span>0.00</span>
              </div>
              <hr />
              <div className="summary-item total">
                <span className="summary-item">SubTotal: </span>
                <span className="summary-item">${Number(subtotal).toFixed(2)}</span>

              </div>
              {/* onClick={makePayment} */}
              <button className="checkout-button" >Complete Checkout</button> or
              {/* <button type="submit" className="checkout-button" style={{backgroundColor: 'blue'}} onClick={handlePayNowClick}> PayPal</button> */}
              <p className="money-back-guarantee">30-Day Money-Back Guarantee</p>
            </div>
          </div>
        </div>
        <div id="root"></div>
        <script src="https://www.paypal.com/sdk/js?client-id=ATBqKPZsH5D2rnAG0-71CrvmoNalm_iUbYvtIwoQ987Ny6qffbeoIjc-IJ9yxa2pAgICJUZtzjlbVkp8"></script>
      </div>
      <Footer />
    </>
  );
};
export default Checkout;